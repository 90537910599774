import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Header from './Components/Header/Header';
import Home from './Containers/Home/Home';
import Services from './Containers/Services/Services';
import ContactUs from './Containers/ContactUs/ContactUs';
import AboutUs from './Containers/AboutUs/AboutUs';
import Footer from './Components/Footer/Footer';
import AirRescueServices from './Containers/AirRescueServices/AirRescueServices';

function App() {
  return (
    <Router>
      <Header /> 
      <Routes>
        <Route exact={true.toString()} path="/"  element={<Home />} /> 
        <Route exact={true.toString()} path="/Services" element={<Services />} />
        <Route exact={true.toString()} path="/AirRescueServices" element={<AirRescueServices/>} />
        <Route exact={true.toString()} path="/contact-us" element={<ContactUs />} /> 
        <Route exact={true.toString()} path="/about-us" element={<AboutUs />} /> 
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;



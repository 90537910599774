import React from 'react'
import './Footer.scss';

const Footer = () => {

  const currentYear = new Date().getFullYear();
  return (
    <>

    <section className='Main-Footer'>
      <div className='footer-img'>
      <img src='/Images/Footer/Aviators-logo.png' alt='Logo' />
      </div>
      <h1>Aviators <span className='bluee'>Air Rescue</span></h1>
      <p className='bluee'>1311 Brigade Towers, #135, Brigade Road, Bangalore – 560 025, Karnataka, India</p>
      <p>Tel:<a>+91 08022219838, +91 08022219839</a>   Fax: <a>+91 08022213849</a> Email: <a href='mailto:info@aviatorsindia.com'>info@aviatorsindia.com</a> GST: 29AANCA0671P1Z1</p>
      <p className='Borrr'></p>
      <p>&copy; {currentYear} Airrescue.in | All Rights Reserved | Powered by <a href='https://www.eveniontechnologies.com/'>Evenion Technologies</a></p>
    </section>

    <section className='Mobile-Main-Footer'>
      <div className='footer-img'>
      <img src='/Images/Footer/Aviators-logo.png' alt='Logo' />
      </div>
      <h1>Aviators <span className='bluee'>Air Rescue</span></h1>
      <p className='bluee'>1311 Brigade Towers, #135, Brigade Road, Bangalore – 560 025, Karnataka, India</p>
      <p>Tel:<a>+91 08022219838,<br></br> +91 08022219839</a> <br></br>  Fax:<a> +91 08022213849 </a><br></br> Email: <a href='mailto:info@aviatorsindia.com'>info@aviatorsindia.com</a> <br></br> GST: 29AANCA0671P1Z1</p>
      <p className='Borrr'></p>
      <p>&copy; {currentYear}  | All Rights Reserved <br></br>| Powered by <a href='https://www.eveniontechnologies.com/'>Evenion Technologies</a></p>
    </section>
    </>
  )
}

export default Footer
import React from 'react';
import './Home.scss'
import CountUp from '../../Components/CountUp/CountUpPage';

const Home = () => {


    return (
        <section className='Main-Home'>
            <img src='/Images/Home/HomeBanner1.png' alt='banner' />
            <div className='home-content'>
                <h1>Aviators</h1>
                <p>30 years of Excellence</p>
            </div>
            <div className='Main-countUp'>
                <CountUp />
            </div>
        </section>
    )
}

export default Home
import React from 'react'
import CountUp from 'react-countup';
import './CountUpPage.scss'

const CountUpPage = () => {
  return (
    <section className='countt-Par'>
      <div className='countt'>
        <span><CountUp start={0} end={30} duration={2.5} delay={0.25} /></span>
        <span>Years of Experience</span>
      </div>
      <div className='countt'>
        <span><CountUp start={0} end={100} duration={2.5} delay={0.25} /> +</span>
        <span>Satisfied Customers</span>
      </div>
      <div className='countt'>
        <span><CountUp start={0} end={250} duration={2.5} delay={0.25} /> +</span>
        <span>International Aircraft Operations</span>
      </div>
    </section>
  )
}

export default CountUpPage






// import React from 'react';
// import CountUp from 'react-countup';
// import './CountUpPage.scss';

// const CountUpPage = () => {
//   return (
//     <section className='countt-Par'>
      
//       <div className='widd'>
//         <div className='countt1'>
//           <div id="container">
//             <div id="halfclip">
//               <div className="halfcircle" id="clipped">
//               </div>
//             </div>
//             <div className="halfcircle" id="fixed"></div>
//           </div>
//           <span><CountUp start={0} end={30} duration={1.5} delay={0.25} /> +  </span>
//         </div>
//         <div> &nbsp; <span> &nbsp; Years of Experience</span></div>
//         </div>
      
// <div className='widd'>
//         <div className='countt2'>
//           <div id="container">
//             <div id="halfclip">
//               <div className="halfcircle" id="clipped">
//               </div>
//             </div>
//             <div className="halfcircle" id="fixed"></div>
//           </div>
//           <span><CountUp start={0} end={100} duration={1.5} delay={0.25} /> +  &nbsp; <span> &nbsp;  Satisfied Customers</span> </span>
//         </div>
//         </div>     
      
// <div className='widd'>
//         <div className='countt3'>
//           <div id="container">
//             <div id="halfclip">
//               <div className="halfcircle" id="clipped">
//               </div>
//             </div>
//             <div className="halfcircle" id="fixed"></div>
//           </div>
//           <span><CountUp start={0} end={250} duration={1.5} delay={0.25} /> +  &nbsp; <span> &nbsp;International Aircraft Operations</span> </span>
//         </div>
//         </div> 
//     </section>
//   );
// }

// export default CountUpPage;




























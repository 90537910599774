import React, { useEffect } from 'react';
import './ContactUs.scss';

const ContactUs = () => {
  
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    // Scroll to top when component mounts
    scrollToTop();

    const handleRefresh = (event) => {
      event.preventDefault(); // Prevents default browser refresh behavior
      scrollToTop();
    };

    // Add event listener to window for refreshing
    window.addEventListener('beforeunload', handleRefresh);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleRefresh);
    };
  }, []);
  return (
    <>
      {/* <section className='Main-ContactUs'>
        <div className='ContactUs-banner'>
          <img src='/Images/ContactUs/IMG-20200804-WA0006.jpg' alt='Service' />
        </div>
        <div className='ContactUs-content'>
          <h1>Contact Us</h1>
        </div>
      </section> */}

      <section className='Contact-Menu'>

        <div className='Contact-left'>
          <h1>Aviators HQ</h1>
          <p>13 th floor Brigade Towers <br></br> 135 Brigade Road
            <br></br>
            Bangalore 560025
            <br></br>
            Tel : +91 08022219838, +91 08022219839
            <br></br>
            Fax : +91 08022213849
            <br></br>
            Email : <a href='mailto:info@aviatorsindia.com'>info@aviatorsindia.com</a>
            <br></br>
            Website : <a href='https://www.aviatorsindia.com/'>www.aviatorsindia.com</a></p>
          <br></br>
        </div>
        <div className='Contact-right'>
          <img src='/Images/ContactUs/BrigadeTower-transformed (1).png' alt='tower' />
        </div>
      </section>
    </>
  )
}

export default ContactUs
import React, { useEffect } from 'react';
import './AboutUs.scss';

const AboutUs = () => {
  
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    // Scroll to top when component mounts
    scrollToTop();

    const handleRefresh = (event) => {
      event.preventDefault(); // Prevents default browser refresh behavior
      scrollToTop();
    };

    // Add event listener to window for refreshing
    window.addEventListener('beforeunload', handleRefresh);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleRefresh);
    };
  }, []);
  return (
    <>
      <section className='Main-AboutUs'>
        <div className='AboutUs-banner'>
          {/* <img src='/Images/AboutUs/AboutBanner5.jpg' alt='Service' /> */}
          <img src='/Images/Services/banner4.png' alt='Service' />
        </div>
        {/* <div className='AboutUs-content'>
          <h1>Discover Our Air Rescue Company</h1>
        </div> */}
      </section>



      {/* <div className='captain-contain'>
        <div className='Captain-info'>
          <div className='imgg'>
            <img src='/Images/AboutUs/Captain-Avaitor.png' alt='Captain' />
          </div>
          <div className='captain-inside-info'>

            <h1>Promoter- Capt. Arun Sharma</h1>
            <h2>Educational Qualifications </h2>
            <p> Bachelor of Arts, Diploma in Business Management, Bachelor of General Law, Export Import Management.</p>
            <h2>Professional Qualifications</h2>
            <p>FAA and Indian Licensed Commercial pilot, Flight Instructor.
              Core competency: Aircraft & Helicopter sales, VIP Aircraft Charter, Aircraft Management.</p>
            <p>Personal Attributes : Result oriented and dynamic senior aviation executive with proven
              skills and excellent track records having covered a diverse range of responsibilities and
              challenges from sales up to a global business responsibility.</p>
            <p>Commercially driven and practical decision maker with top of faculty business degree and
              contemporary general management education. Well versed with rules and regulations of
              FAA and Indian Civil Aviation.</p>
            <p>Leader, Team builder, Dedicated and focused.</p>
          </div>
        </div>

      </div> */}


<div className='Content-main'>
      <div className='Content'>
        <h1>Our Experience </h1>
        <h2>30 years in business</h2>
        <p>Aircraft Sales , Aviation product sales, Operational support for both
          domestic and international operations, Air Medical Rescue Services,
          Charter operations, Corporate and Airline aircraft ferry services.</p>
        <p>Companies represented : Jeppesen, Aircraft Technical Publisher,
          Piper, Pilatus, Piaggio, Grob Aircraft, Aerion, Skyplan.</p>
        <p>Managed Corporate aircrafts for major business houses in India .</p>
        <p>Managed aircraft delivery services for : Damania, East West Airlines,
          Jet Airways, Sahara Airlines, NEPC Airlines, Air Deccan. We have
          experience of over 250 airline ferries.</p>
          <p className='borr1'></p>
      </div>
</div>

    </>
  )
}

export default AboutUs
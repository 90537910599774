import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './Header.scss';
import UseSticky from '../CustomHooks/useSticky';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [flagSD, setFlagSD] = useState(false);
  const [flagProductD, setFlagProductD] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [shrinkHeader, setShrinkHeader] = useState(false);
  const { isSticky, element } = UseSticky();
  const [dropdownHovered, setDropdownHovered] = useState(false);
  const location = useLocation();

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const changeFlagStateSD = () => {
    setFlagSD(!flagSD);
    setFlagProductD(false);
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
      if (window.scrollY > 100) {
        setShrinkHeader(true);
      } else {
        setShrinkHeader(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    let timeoutId;
    if (dropdownHovered) {
      timeoutId = setTimeout(() => {
        setDropdownOpen(false);
      }, 2000);
    } else {
      clearTimeout(timeoutId);
    }

    return () => clearTimeout(timeoutId);
  }, [dropdownHovered]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    // Close the dropdown when the route changes
    setDropdownOpen(false);
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
    {/* desktop header */}
    <header className={`header ${shrinkHeader ? 'shrink' : ''}`}>
      <div className={`logo-container ${shrinkHeader ? 'shrink' : ''}`}>
        <NavLink exact={true.toString()} to="/" >
          <div className={`logo ${shrinkHeader ? 'shrunk' : ''}`}>
            <img src="/Images/Aviators-logo.png" alt="Logo" />
          </div>
        </NavLink>
      </div>
      <div className={`navbar-container ${shrinkHeader ? 'shrink' : ''}`}>
        <ul className="navbar">
          <li>
            <NavLink to="/about-us" >About Us</NavLink>
          </li>
          <li>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className='colorless' onMouseEnter={() => setDropdownHovered(true)} onMouseLeave={() => setDropdownHovered(false)}>
              <NavLink to="/Services" className='colorless' onClick={scrollToTop}>
                <DropdownToggle caret  className='colorless'> {/* Use toggleMenu function */}
                  Services Offered {flagSD ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </DropdownToggle>
              </NavLink>
              <DropdownMenu className="dropdown-menu p-0">
              <DropdownItem className="dropdown-item_modify colorless">
                  <a href="/Services#IDACHeliSales">AirCraft and Helicopter sales</a>
                </DropdownItem>
         
                <DropdownItem className="dropdown-item_modify colorless">
                  <a href="/Services#IDACHeliCharter">AirCraft and Helicopter Charter</a>
                </DropdownItem>
                <DropdownItem className="dropdown-item_modify colorless">
                  <a href="/Services#IDACHeliDelivery">AirCraft & Helicopter Delivery Services</a>
                </DropdownItem>
                <DropdownItem className="dropdown-item_modify colorless">
                  <a href="/Services#IDEngineTransportation">Engine Transportation</a>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
          <li>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className='colorless' onMouseEnter={() => setDropdownHovered(true)} onMouseLeave={() => setDropdownHovered(false)}>
              <NavLink to="/AirRescueServices" className='colorless'>
                <DropdownToggle caret  className='colorless'> {/* Use toggleMenu function */}
                  Air Rescue Services  {flagSD ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </DropdownToggle>
              </NavLink>
              <DropdownMenu className="dropdown-menu p-0">
                <DropdownItem className="dropdown-item_modify colorless">
                  <a href="/AirRescueServices#IDAirAmbulance">Air Ambulance</a>
                </DropdownItem>
                <DropdownItem className="dropdown-item_modify colorless">
                  <a href="/AirRescueServices#IDFMLMS">Rescue Services </a>
                </DropdownItem>
                {/* <DropdownItem className="dropdown-item_modify colorless">
                  <a href="/AirRescueServices#IDFR">Fire Fighting</a>
                </DropdownItem>
                <DropdownItem className="dropdown-item_modify colorless">
                  <a href="/AirRescueServices#IDDR">Disaster Management</a>
                </DropdownItem> */}
              </DropdownMenu>
            </Dropdown>
          </li>
          <li>
            <NavLink to="/contact-us" >Contact Us</NavLink>
          </li>
        </ul>
      </div>
    </header>



    {/* Mobile Design */}
    <div className='Mobile-Header'>
      <div className='Head-L'>        
        <NavLink exact to="/" onClick={closeMenu}>
          <div className='logo-Mobile'>
            <img src="/Images/Aviators-logo.png" alt="Logo" />
          </div>
        </NavLink>
      </div>
      <div className='Head-R' onClick={isMenuOpen ? closeMenu : toggleMenu}>
        {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
      </div>
      {isMenuOpen && (
        <div className='Side-Menu'>
          <ul className="Menu-List">
            <li className='li-link'>
              <NavLink to="/about-us" onClick={closeMenu}>About Us</NavLink>
            </li>
            <li className='li-link'>
              <NavLink to="/Services" onClick={closeMenu}>Services Offered</NavLink>
            </li>
            <li className='li-link'>
            <NavLink to="/AirRescueServices" onClick={closeMenu}>Air Rescue Services</NavLink>
            </li>
            <li className='li-link'>
              <NavLink to="/contact-us" onClick={closeMenu}>Contact Us</NavLink>
            </li>
          </ul>
        </div>
      )}
    </div>

    </>
  );
};

export default Header;


import React, { useEffect } from 'react';

import './Services.scss';

const Services = () => {
  
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    // Scroll to top when component mounts
    scrollToTop();

    const handleRefresh = (event) => {
      event.preventDefault(); // Prevents default browser refresh behavior
      scrollToTop();
    };

    // Add event listener to window for refreshing
    window.addEventListener('beforeunload', handleRefresh);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleRefresh);
    };
  }, []);



  return (
    <>
      {/* <section className='Main-Services'>
        <div className='Service-banner'>
          <img src='/Images/Services/G550.jpg' alt='Service' />
        </div>
        <div className='Service-content'>
          <h1>Explore Our  <br></br> Aircraft & Helicopter <br></br> Delivery Services</h1>
        </div>
      </section> */}


      <section className='Serice-start'>


{/* Service link to navigate to the other pages */}
        {/* <div className='Services-link'>
          <div className='serv-left'>
            <p>SERVICES</p>
            <a href='#IDACHeliDelivery'>AirCraft & Helicopter Delivery Services</a>
            <a href='#IDACHeliCharter'>AirCraft and Helicopter Charter</a>
            <a href='#IDACHeliSales'>AirCraft and Helicopter sales</a>
            <a href='#IDEngineTransportation'>Engine Transportation</a>
          </div>
          <div className='serv-right'>
            <p>
              Curly Airline International proudly raises the bar and exceeds the standard for luxury and corporate private jet charter services. We pride ourselves on offering a professional and personalised service which makes. We can enhance the trip with custom catering and branding of the aircraft. You and your group will also have access to many of the private terminals around the globe.
            </p>
          </div>
        </div> */}



        {/* starts here sales section */}
        <div className='ACHeliSales' id='IDACHeliSales'>
          <div className='sales-left'>
            {/* <img src='/Images/Services/Sales.png' alt='AcheliSales' /> */}
            <div className='flex'>
            <img src='/Images/Services/Sales1.jpg' alt='AcheliSales' />
            <img src='/Images/Services/Sales2.jpg' alt='AcheliSales' />
            </div>
            <div className='flex'>
            <img src='/Images/Services/Sales3.jpg' alt='AcheliSales' />
            <img src='/Images/Services/Sales5.jpg' alt='AcheliSales' />
            </div>
          </div>
          <div className='sales-right'>
            <h1>Aircraft & Helicopter  sales</h1>
            <p className='borr'></p>
            <p>Our market research capabilities and
              experience in sales, guarantee you
              access to the support needed for a
              smooth transaction with either an OEM
              or a reseller</p>
            <p>Experience the efficiency, flexible
              purchasing options and extensive
              inventory availability for you to choose
              the aircraft or helicopter of your choice
              and we will assist you in ensuring that it
              is delivered to start your operations.</p>
            <p>A passionate team of aviation experts
              with strategic approach and action oriented thinking will ensure full
              satisfaction for your purchase decision.</p>
            {/* <a href='/ACHeliSales'>KNOW MORE</a> */}
          </div>
        </div>


        {/* starts here charter section */}
        <div className='ACHeliCharter' id='IDACHeliCharter'>
          <div className='char-left'>
            <h1>Aircraft & Helicopter Charter </h1>
            <p className='borr'></p>
            <p>A private jet charter also takes the stress
              out of traveling for leisure. Whether
              journeying alone, as part of a small party
              or family, the generous cabin interior of
              a large team or family occasion we offer
              all aircraft sizes.</p>
            <p>Travel within India with a sales team or a
              destination marriage function we have an
              aircraft for all occasions.</p>
            <p>Our experience of 27 years in the industry
              ensures the quality is delivered as
              expected.</p>
            {/* <a href='/ACHeliCharter'>KNOW MORE</a> */}
          </div>
          <div className='char-right'>
            {/* <img src='/Images/Services/Charter.png' alt='ACHeliCharter' /> */}
            <div className='flex'>
            <img src='/Images/Services/Charter8.jpg' alt='AcheliSales' />
            <img src="/Images/Services/Charter7.jpg" alt='AcheliSales' />
            </div>
            <div className='flex'>
            <img src='/Images/Services/Charter5.jpg' alt='AcheliSales' />
            <img src='/Images/Services/Charter6.jpg' alt='AcheliSales' />
            </div>
          </div>
        </div>


        {/* starts here Delivery section */}
        <div className='ACHeliDelivery' id='IDACHeliDelivery'>

          <div className='del-left flex1-container'>
            {/* <img src='/Images/Services/Delivery.png' alt='ACHeliDelivery' /> */}
            <div className='flex1'>
            {/* <img src='/Images/Services/Delivery1.jpg' alt='AcheliSales' />
            <img src='/Images/Services/Delivery2.jpg' alt='AcheliSales' />
            <img src='/Images/Services/Delivery3.jpg' alt='AcheliSales' /> */}
            <img src='/Images/Services/Delivery-main2.jpg' alt='AcheliSales' /> 
            </div>
          </div>
          <div className='del-right'>
            <h1>Aircraft & Helicopter  Delivery Services</h1>
            <p className='borr'></p>
            <p>Our aircraft ferry service encompasses every requirement needed in
              order to complete any ferry or delivery flight including flight planning,
              permissions for aircraft with full or limited airworthiness as well as
              those operating on a permit to fly, crew travel arrangements, fueling
              and ground handling worldwide.</p>
            <p>With over 250 ferries for Indian airlines and corporate aircrafts, our
              experience is unmatched in India. We can provide ferry pilots for
              airlines and corporate aircrafts on turnkey basis.</p>
            <p>Ferries conducted for Jet Airways, Sahara Airlines, East West Airlines,
              Damania Airways, Blue Dart ,NEPC Airlines, Air Deccan. Corporate
              sector aircraft managed for international operations like Reliance,
              ESSAR, Hinduja, Oberoi, TATA, BIRLA, Raymonds, Bombay Dyeing.</p>
            {/* <a href='/ACHeliDelivery'>KNOW MORE</a> */}
          </div>
        </div>


        {/* starts Engine transportation */}
        <div className='EngineTransportation' id='IDEngineTransportation'>
          <div className='Eng-left'>
            <h1>Engine Transportation</h1>
            <p className='borr'></p>
            <p>Aircraft engine transportation requires
              cross-channel logistics support, often on
              tight timelines.</p>
            <p>Our extensive domestic and international
              aviation experience, regional connections
              and network of specialized equipment is
              effective for the speed you need to get
              aircraft back in the sky as soon as
              possible.</p>
            <p>Aviators network will deliver just the right
              combination of ground and air transport
              required to move your engine and parts to
              global destinations on the narrowest of
              timelines</p>
            {/* <a href='/EngineTransportation'>KNOW MORE</a> */}
          </div>
          <div className='Eng-right'>
            <img src='/Images/Services/Engine-Transportation.jpg' alt='EngineTransportation' />
          </div>
        </div>

      </section>
    </>
  )
}

export default Services
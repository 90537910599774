import React, { useEffect } from 'react';
import './AirRescueServices.scss'

const AirRescueServices = () => {


 
    useEffect(() => {
        const scrollToTop = () => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        };
        scrollToTop();
        const handleRefresh = (event) => {
          event.preventDefault(); 
          scrollToTop();
        };  
        window.addEventListener('beforeunload', handleRefresh);
        return () => {
          window.removeEventListener('beforeunload', handleRefresh);
        };
      }, []);

    return (
        <>
            {/* <section className='Main-AirRescueServices'>
                <div className='AirRescueServices-banner'>
                    <img src='/Images/Services/banner4.png' alt='Service' />
                </div>
                <div className='AirRescueServices-content'>
                    <h1>Explore More on AirRescue</h1>
                </div>
            </section> */}
            <div></div>

            <section className='Serice-start'>

                {/* Service link to navigate to the other pages */}
                {/* <div className='Services-link'>
                    <div className='serv-left'>
                        <p>AIR RESCUE</p>
                        <a href='#IDAirAmbulance'>Air Ambulance</a>
                        <a href='#IDFMLMS'>First mile - Last mile </a>
                        <a href='#IDFR'>Fire Rescue</a>
                        <a href='#IDDR'>Disaster Rescue</a>
                    </div>
                    <div className='serv-right'>
                        <p>
                            Curly Airline International proudly raises the bar and exceeds the standard for luxury and corporate private jet charter services. We pride ourselves on offering a professional and personalised service which makes. We can enhance the trip with custom catering and branding of the aircraft. You and your group will also have access to many of the private terminals around the globe.
                        </p>
                    </div>
                </div> */}


                {/* starts here Delivery section */}
                <div className='ACHeliDelivery airAmbulance' id='IDAirAmbulance'>

                    <div className='del-left'>
                        <img src='/Images/Services/AIrAbulance1.png' alt='ACHeliDelivery' />
                    </div>
                    <div className='del-right'>
                        <h1>Air Ambulance</h1>
                        <p className='borr'></p>
                        <p>First in the country to start dedicated Air
                            Ambulance services catering to Hospitals
                            and individuals through subscription and
                            on demand charter services.</p>
                        <p>We have catered to emergency and non emergency services for patients, organs,
                            neo natal and medical cargo including
                            medical teams.</p>
                        <p>Aviators network with and the right
                            combination of ground and air transport
                            has saved many lives.</p>
                        {/* <a href='/ACHeliDelivery'>KNOW MORE</a> */}
                    </div>
                </div>

                {/* starts here charter section */}
                <div className='ACHeliCharter FirstMile' id='IDFMLMS'>
                    <div className='char-left'>
                        <h1>First mile - Last mile Services</h1>
                        <p className='borr'></p>
                        
                    </div>
                    <div className='char-right'>
                        <img src='/Images/Services/FirstMile-LastMile.jpg' alt='ACHeliCharter' />
                    </div>
                </div>





<div className='FireFighting'></div>

                {/* starts here AiurREscue section */}
                <div className='ACHeliSales1' id='IDFR'>
                    <div className='sales-left1'>
                        <div className='firee'><img src='/Images/Services/helicopterFire3.jpg' alt='AcheliSales' /></div>
                        <div className='firee'><img src='/Images/Services/helicopterFire2.jpg' alt='AcheliSales' /></div>
                        <div className='firee'><img src='/Images/Services/helicopterFire1.jpg' alt='AcheliSales' /></div>
                    </div>
                    <div className='sales-right1'>
                        <h1>Fire Fighting</h1>
                        <p className='borr'></p>
                    </div>
                </div>

                {/* starts Engine transportation */}
                <div className='EngineTransportation1' id='IDDR'>
                    <div className='Eng-left1'>
                         <h1>Disaster Management</h1>
                        <p className='borr'></p>
                        </div>
                        <div className='Eng-right1'>
                        <div className='firee'><img src='/Images/Services/Disterimg1 (1).jpg' alt='AcheliSales' /></div>
                        <div className='firee'><img src='/Images/Services/Disterimg2 (1).jpg' alt='AcheliSales' /></div>
                        <div className='firee'><img src='/Images/Services/Disterimg3 (1).png' alt='AcheliSales' /></div>
                    </div>
                </div>




    
            </section>

        </>

    )
}

export default AirRescueServices